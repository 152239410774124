<title>问卷列表</title>

<style>

    .ul-no li {
        text-align: center;
    }

    ul {
        list-style: none;
    }

    .ul-no li a {
        display: block;
        text-decoration: none;
    }
</style>

<template>
<div class="container" id="app">
    <div class="row">
        <div class="col-12 text-center mt-2">
            <h2 class="fs-5">感谢您对全省商务事业的支持 ！</h2>
<!--            <h2 class="fs-5">2023年1月份外贸企业调查问卷填报时间临时调整为1月28日至2月4日。感谢您过去一年的支持，祝您新年快乐 ！</h2>-->
            <p class="text-secondary" v-if="company.name">{{company.name}}（{{company.uscc}}）</p>
        </div>

        <div class="col-12 main-box">
            <!--问卷列表-->
            <ul class="ul-no list-group">
                <li v-for="s in surveyList" :key="s.wjdoId" class="list-group-item">
                    <a @click="wjView(s)"
                       v-if="s.numberPeople === 0 || s.wjdoCanEdit === 1">
                        <small style="display:block;" v-if="s.gameCode" class="text-center text-danger">
                            <i class="fa fa-heart"></i> 参与有惊喜！
                        </small>
                        <div class="fs-6 text-start"><b>{{s.wjdoTitle}}</b></div>
                        <div class="text-start text-dark">{{s.wjdoTitleSub}}</div>
                        <div class="p text-start text-secondary">
                            <small>时间：{{s.wjdoDateBegin}} - {{s.wjdoDateEnd}}</small>
                        </div>
                        <div class="p text-start text-muted" v-if="s.numberPeople > 0">
                            <small v-if="s.wjdoCanEdit === 1">已填写，点击可修改</small>
                            <small v-else>已填写，感谢您的参与！</small>
                        </div>
                    </a>
                </li>

                <li v-for="s in surveyOtherList" :key="s.wjdoId" class="list-group-item">
                    <a :href="s.href">
                        <div class="fs-6 text-start"><b>{{s.title}}</b></div>
                        <div class="text-start text-dark">{{s.title_sub}}</div>
                        <div class="p text-start text-secondary">
                            <small>时间：{{s.date_begin}} - {{s.date_end}}</small>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-12 mt-2">
            <!--报表列表-->
            <ul class="ul-no ps-0">
                <li v-for="r in reports" :key="r.id" class="text-center">
                    <span class="text-success" style="display: block" @click="openPdf(r.id)">《{{r.name}}》</span>
                    <small>（内部参加，请勿外传，文档大小：{{Math.ceil(parseInt(r.size) / 1024)}} K</small>
                </li>
            </ul>
        </div>
        <div class="col-12">
            <br/>
            <hr/>
            <br/>
            <div class="col-12 text-center">
                为了便于问卷问题沟通，请您添加以下微信为好友。<br/>
                <small class="help">搜索微信号：</small><br/>
                <input type="text" style="font-size:18px;border:0" class="copytext text-center" readonly="readonly"
                       value="13245318357"/>
                <br/>
                <small class="help">或手机号：</small>
                <br/><input type="text" style="font-size:18px;border:0;" class="copytext text-center"
                            readonly="readonly" value="13245318357"/>
                <br/>
                <small class="help">请注明您的公司名称，谢谢！<br/>（此微信交流不涉及任何个人隐私、金融账号等敏感信息）</small>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Vue from 'vue'
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'
import request from '@/utils/request.js'

import $ from '../../../assets/jquery-3.6.0.min'
export default {

        data() {
            return {
                company: {},
                surveyList: [],
                surveyOtherList: [],
                reports: [],
                uscc: '',
                // cid: ${cid},
                // uid: ${uid}
                cid: '',
                uid: ''
            }
        },
        watch: {
            company(val, oldVal) {
                this.uscc = val.uscc
                if (val.uscc) {
                    this.listSurvey()
                    this.listOtherSurvey()
                }
            }
        },
        mounted() {
						this.cid = this.$route.query.cid
						this.uid = this.$route.query.uid
            this.getCompany()
            this.listReport()
            this.copyText()
        },
        methods: {
					wjView(s){
						console.log('s','/mp/view/survey/view/?uid=' + this.uid + '/' + this.cid + '/' + s.wjdoId + '?v=' + new Date().getTime());
					//this.$router.push('/mp/view/survey/view/' + this.uid + '/' + this.cid + '/' + s.wjdoId + '?v=' + new Date().getTime())
					this.$router.push({
						path:"/mp/view/survey/view",
						query:{
							uid:this.uid,
							cid:this.cid,
							wjdoId:s.wjdoId,
							v:new Date().getTime()
						}
					})
					},
            getCompany() {
                let _this = this
                request.get('/api/company/temp/get/' + this.cid)
                    .then(res => {
                        _this.company = res.data
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            listSurvey() {
                let _this = this
                request.get('/api/survey/list/enabled/' + this.uid + '?uscc=' + this.uscc)
                    .then(res => {
                        _this.surveyList = res
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            listOtherSurvey() {
                let _this = this
                request.get('/api/survey/list/other/' + this.uid + '?uscc=' + this.uscc)
                    .then(res => {
                        _this.surveyOtherList = res
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            listReport() {
							let _this = this
							request.get('/api/report/list')
								.then(res => {
										_this.reports = res
								})
								.catch(function (error) {
										console.log(error);
								});
            },
            openPdf(id) {
							this.$wx.miniProgram.navigateTo({
                    url: '/pages/ex/preview?id=' + id
                });
            },
            copyText() {
                $("body").on("click", ".copytext", function () {
                    var a = $(this);
                    a.select();
                    try {
                        document.execCommand("Copy");
                        layer(Vue).msg("复制成功，可以返回微信主窗口搜索!")
                    } catch (b) {
                        layer(Vue).msg("请长按后复制")
                    }
                });
            }
        }
    }

</script>